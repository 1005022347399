import React from 'react'
import './testimonials.css'
import IMG from '../../assets/IMG-7703.jpg'

// import Swiper core and required modules
import { Pagination } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';


const Testimonials = () => {
  return (
    <section id="testimonials">
      <h5>Review from clients</h5>
      <h2>Testimonials</h2>

      <Swiper className="container testimonials__container"
      // install Swiper modules
      modules={[Pagination]}
      spaceBetween={40}
      slidesPerView={1}
      pagination={{ clickable: true }}>
        <SwiperSlide className="testimonial">
          <div className="client__avatar">
            <img src={IMG} alt="avatar one" />
          </div>
          <h5 className="client_name">Mariam Muchai</h5>
            <small className="client_review">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
               Recusandae, quam! Soluta eveniet amet sed exercitationem 
               fuga minima voluptates quod numquam consequatur iste? 
               Molestiae voluptatem laborum, ea obcaecati quod error voluptas.
            </small>
        </SwiperSlide>
        <SwiperSlide className="testimonial">
          <div className="client__avatar">
            <img src={IMG} alt="avatar one" />
          </div>
          <h5 className="client_name">Mariam Muchai</h5>
            <small className="client_review">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
               Recusandae, quam! Soluta eveniet amet sed exercitationem 
               fuga minima voluptates quod numquam consequatur iste? 
               Molestiae voluptatem laborum, ea obcaecati quod error voluptas.
            </small>
        </SwiperSlide>
        <SwiperSlide className="testimonial">
          <div className="client__avatar">
            <img src={IMG} alt="avatar one" />
          </div>
          <h5 className="client_name">Mariam Muchai</h5>
            <small className="client_review">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
               Recusandae, quam! Soluta eveniet amet sed exercitationem 
               fuga minima voluptates quod numquam consequatur iste? 
               Molestiae voluptatem laborum, ea obcaecati quod error voluptas.
            </small>
        </SwiperSlide>
        <SwiperSlide className="testimonial">
          <div className="client__avatar">
            <img src={IMG} alt="avatar one" />
          </div>
          <h5 className="client_name">Mariam Muchai</h5>
            <small className="client_review">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
               Recusandae, quam! Soluta eveniet amet sed exercitationem 
               fuga minima voluptates quod numquam consequatur iste? 
               Molestiae voluptatem laborum, ea obcaecati quod error voluptas.
            </small>
        </SwiperSlide>
        
       
        
      </Swiper>
    </section>
  )
}

export default Testimonials